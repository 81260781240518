/* GENERAL */

.no__bottom_margin {
  margin-bottom: 0px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.elements__on_sides {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* INTEGRATION STYLES */

.service {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  border-top: 2px solid rgb(18, 38, 63, 0.03);
  border-bottom: 2px solid rgb(18, 38, 63, 0.03);
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  border-radius: 0.25rem;
  background-color: #ffffff;
}

.service img {
  margin-right: 25px;
}

.service__tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  box-shadow: 0 0 18px rgba(0, 0, 0, 0.12);
  border-radius: 0.25rem;
  background-color: #ffffff;
  height: 200px;
}

/* SETTINGS STYLES */
.col__horizontal_centered {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.col__padding_bigger {
  padding-left: 2rem;
  padding-right: 2rem;
}

@media only screen and (min-width: 992px) {
  .border_right {
    border-right: 1px solid #d3d8dd;
  }
}

/* FORM STYLES */
.only_bottom_border {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d3d8dd;
}

.full_width {
  width: 100%;
}

.width_60 {
  width: 60%;
}

.width_90 {
  width: 90%;
}

.label_grey {
  color: #818e9b;
}
